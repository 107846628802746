@charset "utf-8";

.e-title {
  &__01 {
    margin-bottom: 1em;
    font-size: 32px;
    color: $e-base;
    letter-spacing: .2em;
    @include pc {
      font-size: 60px;
    }
    i {
      display: block;
      @include font-FilsonPro_Bold;
      line-height: 1;
    }
    span {
      display: block;
      position: relative;
      margin-top: .5em;
      padding-left: 30px;
      @include font-noto_sans_bold;
      font-size: 13px;
      letter-spacing: .02em;
      @include pc {
        font-size: 14px;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        width: 26px;
        height: 2px;
        background-color: $e-base;
      }
    }
  }
  &__02 {
    margin-bottom: 1em;
    @include font-noto_sans_bold;
    font-size: 16px;
    @include pc {
      font-size: 24px;
    }
    i {
      display: block;
      font-size: 13px;
      font-style: normal;
      @include pc {
        font-size: 16px;
      }
    }
    & ~ &,
    &._add__margin {
      margin-top: 2em;
    }
  }
  &__03 {
    margin-bottom: 1em;
    @include font-noto_sans_bold;
    font-size: 14px;
    color: #8b7e69;
    letter-spacing: .08em;
    @include pc {
      font-size: 20px;
      letter-spacing: .16em;
    }
  }
  &__04 {
    @include font-noto_sans_bold;
    font-size: 14px;
    @include pc {
      font-size: 16px;
    }
    & ~ & {
      margin-top: 1em;
    }
  }
  &__05 {
    @include font-noto_sans_bold;
    font-size: 12px;
    @include pc {
      font-size: 16px;
    }
    & ~ & {
      margin-top: 1em;
    }
  }
}
