@charset "utf-8";

body {
  background-color: $e-primary;
}
p {
  font-size: 14px;
  line-height: (56 / 28);
  letter-spacing: 0;
  @include pc {
    font-size: 16px;
  }
}
