@charset "utf-8";

.e-gnav {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 60px;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 60px);
  background-color: $e-base;
  @include font-FilsonPro_Bold;
  font-size: 18px;
  letter-spacing: .2em;
  transition: .3s ease;
  transform: translateY(-100vh);
  @include pc {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    height: calc(100% - 80px);
    font-size: pxToVw(20px);
  }
  .-navopen & {
    pointer-events: auto;
    transform: translateY(0);
  }
  a {
    display: inline-block;
    color: $e-white;
    text-decoration: none;
    @include ov;
    &[target="_blank"] {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .5em;
        width: 16px;
        height: 12px;
        background: url("/img/common/ico_blank_01.svg") no-repeat left top / contain;
        @include pc {
          width: pxToVw(16px);
          height: pxToVw(12px);
        }
      }
    }
  }
  &__inner {
    width: 100%;
    padding: calc(60 / 750 * 100%);
    @include pc {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__list {
    @include pc {
      margin: 0 calc(40 / 1240 * 100%);
    }
  }
  &__item {
    position: relative;
    margin-top: 1em;
    &.-indent {
      padding-left: 30px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        width: 20px;
        height: 2px;
        background-color: $e-white;
      }
    }
    &.-store {
      margin-left: 3em;
      @include font-noto_sans_medium;
      font-size: 13px;
      @include pc {
        font-size: pxToVw(16px);
      }
      a {
        color: $e-tertiary;
      }
      i {
        display: block;
        margin-right: 1em;
        @include font-noto_sans_regular;
        font-size: 10px;
        font-style: normal;
        letter-spacing: .02em;
        white-space: nowrap;
        @include pc {
          font-size: pxToVw(12px);
        }
      }
      span {
        white-space: nowrap;
      }
    }
    &.-contact {
      margin-top: 2em;
      font-size: 13px;
      @include pc {
        font-size: pxToVw(14px);
      }
    }
  }
}
