@charset "utf-8";

.e-inner {
  margin-right: auto;
  margin-left: auto;
  padding: 0 calc(60 / 750 * 100%);
  width: 100%;
  @include pc {
    padding: 0 pxToVw(100px);
    max-width: 1440px;
  }
  &__inner {
    @include pc {
      padding: 0 pxToVw(80px);
    }
  }
  &__white {
    padding: 30px;
    background-color: $e-white;
    color: $e-base;
    @include pc {
      padding: 60px 80px;
    }
  }
}
.e-section {
  padding: 60px 0;
  @include pc {
    padding: 160px 0;
  }
}
