@charset "utf-8";

.e-header {
  z-index: 1000;
}
.e-gnav {
  z-index: 900;
}
.e-story .e-image,
.e-philosophy .e-image {
  position: relative;
  z-index: 2;
}
