@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//5〜200pxまで5px刻みで生成
// SP
//.-sp-margin_top5 {
//  margin-top: 5px
//}
//
//.-sp-margin_right_5 {
//  margin-right: 5px
//}
//
//.-sp-margin_bottom_5 {
//  margin-bottom: 5px
//}
//
//.-sp-margin_left_5 {
//  margin-left: 5px
//}
//
// PC
//.-pc-margin_top5 {
//  margin-top: 5px
//}
//
//.-pc-margin_right_5 {
//  margin-right: 5px
//}
//
//.-pc-margin_bottom_5 {
//  margin-bottom: 5px
//}
//
//.-pc-margin_left_5 {
//  margin-left: 5px
//}
//

// 指定するmarginの範囲を定義
$margin-min: 1;
$margin-max: 200;

@for $i from $margin-min through $margin-max {
  .-sp-margin_top#{$i} {
    @include sp {
      margin-top: #{$i}px !important;
    }
    @include tb {
      margin-top: #{$i}px !important;
    }
  }
  .-sp-margin_right#{$i} {
    @include sp {
      margin-right: #{$i}px !important;
    }
    @include tb {
      margin-right: #{$i}px !important;
    }
  }
  .-sp-margin_bottom#{$i} {
    @include sp {
      margin-bottom: #{$i}px !important;
    }
    @include tb {
      margin-bottom: #{$i}px !important;
    }
  }
  .-sp-margin_left#{$i} {
    @include sp {
      margin-left: #{$i}px !important;
    }
    @include tb {
      margin-left: #{$i}px !important;
    }
  }
  body .-pc-margin_top#{$i} {
    @include pc {
      margin-top: #{$i}px !important;
    }
  }
  body .-pc-margin_right#{$i} {
    @include pc {
      margin-right: #{$i}px !important;
    }
  }
  body .-pc-margin_bottom#{$i} {
    @include pc {
      margin-bottom: #{$i}px !important;
    }
  }
  body .-pc-margin_left#{$i} {
    @include pc {
      margin-left: #{$i}px !important;
    }
  }
}
