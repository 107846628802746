@charset "utf-8";

.e-shop {
  background-color: $e-secondary;
  .e-title__01 {
    color: $e-white;
    span::before {
      background-color: $e-white;
    }
  }
  &__inner {
    margin-top: 20px;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
    .e-inner {
      padding-top: 50px;
      padding-bottom: 50px;
      background-color: #f2ede8;
      @include pc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        width: 50%;
      }
    }
  }
  .e-image {
    width: 100%;
    height: 100vw;
    background-color: $e-border;
    @include pc {
      width: 50%;
      height: 50vw;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      @include ov;
    }
    img {
      width: 100%;
    }
  }
  &__list {
    margin-bottom: 30px;
    font-size: 13px;
    @include pc {
      font-size: 16px;
    }
  }
  &__item {
    @include pc {
      display: flex;
    }
  }
  &__title {
    margin-top: 1em;
    color: #8b7e69;
    @include pc {
      margin-top: 0;
      width: 6em;
    }
  }
  &__text {
    margin-top: .25em;
    @include pc {
      flex: 1;
      margin-top: 0;
      padding-left: 1em;
      text-indent: -1em;
    }
    &::before {
      @include pc {
        content: '：';
        color: #8b7e69;
      }
    }
  }
}
