@charset "utf-8";

.e-mainVisual {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  padding-top: pxToVw(200px);
  width: 100%;
  height: calc(100vh - 60px);
  background: url("/img/top/mainvisual_pic_01.jpg") no-repeat center center / cover;
  @include pc {
    justify-content: center;
    padding-top: 80px;
    height: calc(700px - 80px);
    background-image: url(/img/top/mainvisual_pic_01_pc.jpg);
  }
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  &__lead {
    margin-bottom: 1em;
    @include font-noto_sans_medium;
    font-size: 14px;
    text-shadow: 0 0 10px rgba(255,255,255,1);
    color: $e-base;
    @include pc {
      margin-bottom: 2em;
      font-size: 18px;
      text-shadow: 0 0 15px rgba(0,0,0,.9);
      color: $e-white;
    }
  }
  &__title {
    margin: 0;
    width: 208px;
    height: 69px;
    @include pc {
      width: 339px;
      height: 113px;
    }
    img {
      filter: drop-shadow(0 0 10px rgba(255,255,255,1));
      @include pc {
        filter: drop-shadow(0 0 30px rgba(0,0,0,.9));
      }
    }
  }
  &__scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 130px;
    height: 60px;
    background-color: $e-base;
    @include font-Montserratfont-Montserrat;
    color: $e-white;
    transform: rotate(90deg) translateY(60px);
    transform-origin: right bottom;
    @include pc {
      justify-content: flex-end;
      width: calc(100% - 80px);
      height: 100px;
      transform: rotate(90deg) translateY(100px);
    }
    span {
      font-size: 10px;
      letter-spacing: .26em;
      transform: scale(.9);
      @include pc {
        font-size: 12px;
        letter-spacing: .2em;
        transform: none;
      }
    }
    &::after {
      content: '';
      display: block;
      margin-left: 10px;
      width: 25px;
      height: 1px;
      background-color: $e-white;
      @include pc {
        width: 40px;
      }
    }
  }
  &-shop {
    position: relative;
    padding-top: 50px;
    @include pc {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      padding: pxToVw(170px) 0 pxToVw(150px);
      width: 100%;
      height: pxToVw(1000px);
    }
    @include wide {
      align-content: center;
    }
    &::before {
      @include pc {
        pointer-events: none;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: pxToVw(410px);
        height: 100%;
        background-color: $e-secondary;
      }
    }
    &__head {
      @include pc {
        order: 1;
        width: calc(466px + #{pxToVw(80px)} * 2);
      }
      .e-inner {
        @include pc {
          padding-right: 0;
        }
      }
    }
    &__category {
      margin-bottom: 1em;
      @include font-FilsonPro_Bold;
      font-size: 18px;
      color: $e-secondary;
      letter-spacing: .2em;
      @include pc {
        font-size: 24px;
      }
    }
    &__title {
      margin: 0 0 .5em;
      @include font-noto_sans_bold;
      font-size: 28px;
      letter-spacing: .16em;
      @include pc {
        font-size: 32px;
      }
      i {
        display: block;
        font-size: 12px;
        font-style: normal;
        letter-spacing: .08em;
        @include pc {
          font-size: 16px;
        }
      }
    }
    &__lead {
      margin-bottom: 1em;
      font-size: 13px;
      @include pc {
        font-size: 16px;
      }
    }
    &__image {
      @include pc {
        flex: 1;
        order: 3;
        position: relative;
        z-index: 2;
        margin-right: pxToVw(100px);
        width: pxToVw(620px);
      }
      @include wide {
        display: flex;
        align-items: center;
      }
      img {
        width: 100%;
      }
    }
    > .e-inner:last-child {
      background-color: $e-secondary;
      @include pc {
        order: 2;
        padding: 0;
        width: 466px;
        background-color: transparent;
      }
    }
    &-line {
      position: relative;
      margin-top: -40px;
      padding: 25px 20px;
      background-color: $e-white;
      text-align: center;
      @include pc {
        margin-top: 30px;
        padding: 0;
        width: 466px;
        background-color: transparent;
      }
      &__text {
        @include font-noto_sans_bold;
        font-size: 12px;
        line-height: (32 / 24);
        @include pc {
          font-size: 16px;
        }
      }
      .e-button__line {
        margin: 10px auto 5px;
        //max-width: 205px;
        width: 100%;
        @include pc {
          max-width: 466px;
        }
      }
      &__note {
        font-size: 12px;
      }
    }
  }
  &-reservation {
    margin-top: 30px;
    &__text {
      color: $e-white;
      margin-top: 1em;
      font-size: 12px;
      text-align: center;
      @include pc {
        color: $e-base;
      }
      strong {
        font-weight: bold;
      }
    }
  }
}
