@charset "utf-8";

.e-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: $e-base;
  color: $e-white;
  z-index: 100;
  @include pc {
    height: 80px;
  }
  &__siteLogo {
    margin-left: 15px;
    margin-right: auto;
    width: 100px;
    height: 33.33px;
    @include pc {
      margin-left: 40px;
      width: 104px;
      height: 34px;
    }
  }
  &__back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    @include font-FilsonPro_Regular;
    font-size: 12px;
    color: $e-gold;
    text-decoration: none;
    @include pc {
      width: 128px;
    }
    &:hover {
      background-color: $e-dark;
    }
    &::before {
      content: '';
      display: block;
      margin-right: 10px;
      width: 6px;
      height: 12px;
      background: url("/img/common/ico_arrow_l_01.svg") no-repeat left center / contain;
    }
    i {
      font-style: normal;
      display: none;
      @include pc {
        display: inline;
      }
    }
    .-etop & {
      display: none;
    }
  }
  &__reservation {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $e-gold;
    width: 80px;
    height: 100%;
    background-color: $e-gold;
    @include font-FilsonPro_Bold;
    font-size: 12px;
    color: $e-dark;
    text-decoration: none;
    @include pc {
      width: 128px;
    }
    &:hover {
      background-color: $e-white;
    }
    i {
      font-style: normal;
      display: none;
      @include pc {
        display: inline;
      }
    }
    .-etop & {
      margin-left: auto;
      border-left: 1px solid $e-dark;
    }
  }
  &__btn {
    cursor: pointer;
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    @include pc {
      width: 100px;
      height: 80px;
    }
    &::before,
    &::after,
    > span::before,
    > span::after {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 11px);
      width: 22px;
      height: 2px;
      background-color: $e-white;
      transition: all .3s ease;
      @include pc {
        left: calc(50% - 13px);
        width: 26px;
      }
    }
    &::before {
      top: calc(50% - 8px);
      @include pc {
        top: calc(50% - 18px);
      }
    }
    &::after {
      bottom: calc(50% - 10px);
      @include pc {
        bottom: calc(50%);
      }
    }
    > span::before {
      @include pc {
        top: calc(50%  - 10px);
      }
    }
    > span::after {
      @include pc {
        top: calc(50%  - 10px);
      }
    }
    i {
      &::before {
        display: block;
        position: absolute;
        left: 50%;
        bottom: calc(50% - 18px);
        background-color: transparent;
        @include font-Montserrat_medium;
        font-size: 10px;
        font-style: normal;
        letter-spacing: .2em;
        transform: translateX(-50%) scale(.7);
      }
      &::before {
        @include pc {
          content: 'MENU';
        }
      }
    }
    &:hover {
      background-color: $e-secondary;
    }
    .-navopen & {
      background-color: $e-secondary;
      color: $e-base;
      &::before,
      &::after {
        transform: scaleX(0);
      }
      > span {
        &::before {
          background-color: $e-base;
          transform: rotate(-45deg);
        }
        &::after {
          background-color: $e-base;
          transform: rotate(45deg);
        }
      }
      i {
        &::before {
          @include pc {
            content: 'CLOSE';
          }
        }
      }
    }
  }
}
