@charset "utf-8";

.e-story {
  position: relative;
  @include pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__lead01 {
    @include font-Montserrat_semiBold;
    font-size: 14px;
    letter-spacing: .02em;
    @include pc {
      font-size: 20px;
    }
  }

  &::before {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -180px;
    width: 100%;
    height: 338px;
    background-color: $e-base;
    @include pc {
      top: 0;
      bottom: auto;
      width: pxToVw(565px);
      height: calc(100% + 423px);
    }
  }
  .e-content {
    width: pxToVw(718px);
  }
  .e-inner {
    @include pc {
      width: auto;
    }
  }
  .e-image {
    margin: 30px 0 0 30px;
    @include pc {
      margin: 0;
      width: pxToVw(722px);
    }
    img {
      width: 100%;
    }
  }
}
