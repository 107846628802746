@charset "utf-8";

%e-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid;
  border-radius: 3em;
  padding: .5em 1em;
  min-height: 50px;
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  transition: .3s ease;
  @include font-Montserratfont-Montserrat;
  @include pc {
    max-width: 520px;
    font-size: 16px;
  }
}
%e-button__arrow {
  &::after {
    @include pc {
      content: '';
      display: block;
      position: absolute;
      right: 30px;
      top: calc(50% - 7px);
      width: 9px;
      height: 14px;
      background: url("/img/common/ico_arrow_r_01.svg") no-repeat left top / contain;
    }
  }
}
%e-button__blank {
  &[target="_blank"] {
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 15px;
      top: calc(50% - 6px);
      width: 16px;
      height: 12px;
      background: url("/img/common/ico_blank_02.svg") no-repeat left top / contain;
      @include pc {
        right: auto;
        left: 32px;
      }
    }
    &:hover {
      &::before {
        background-image: url("/img/common/ico_blank_03.svg");
      }
    }
  }
}
%e-button__align {
  &.-right {
    @include pc {
      margin-left: auto;
    }
  }
  &.-center {
    @include pc {
      margin-right: auto;
      margin-left: auto;
    }
  }
  & + & {
    margin-top: 10px;
  }
}
.e-button {
  &__black {
    @extend %e-button;
    @extend %e-button__arrow;
    @extend %e-button__blank;
    @extend %e-button__align;
    border-color: $e-base;
    background-color: $e-base;
    color: $e-white;
    &:hover {
      background-color: $e-white;
      color: $e-base;
      &::after {
        @include pc {
          background-image: url("/img/common/ico_arrow_r_04.svg");
        }
      }
    }
  }
  &__red {
    @extend %e-button;
    @extend %e-button__arrow;
    @extend %e-button__blank;
    @extend %e-button__align;
    border-color: $e-red;
    background-color: $e-red;
    color: $e-white;
    &[target="_blank"] {
      &::before {
        background: url("/img/common/ico_blank_02.svg") no-repeat left top / contain;
      }
      &:hover {
        &::before {
          background-image: url("/img/common/ico_blank_04.svg");
        }
      }
    }
    &:hover {
      background-color: $e-white;
      color: $e-red;
      &::after {
        @include pc {
          background-image: url("/img/common/ico_arrow_r_05.svg");
        }
      }
    }
  }
  &__line {
    @extend %e-button;
    @extend %e-button__arrow;
    @extend %e-button__blank;
    border-color: #06c755;
    background-color: #06c755;
    font-size: 16px;
    color: $e-white;
    @include pc {
      border-radius: 3em;
      min-height: 100px;
      font-size: 24px;
    }
    &::before {
      content: '';
      position: absolute;
      right: 18px;
      top: calc(50% - 6px);
      width: 6px;
      height: 12px;
      background: url("/img/common/ico_arrow_r_01.svg") no-repeat left top / contain;
      @include pc {
        right: 40px;
        top: calc(50% - 12px);
        width: 12px;
        height: 24px;
      }
    }
    &:hover {
      background-color: $e-white;
      color: #06c755;
      &::before {
        background-image: url("/img/common/ico_arrow_r_03.svg");
      }
    }
  }
}
