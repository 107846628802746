@charset "utf-8";

.e-footer {
  width: 100%;
  background-color: $e-dark;
  @include font-Montserrat_medium;
  font-size: 12px;
  color: $e-tertiary;
  letter-spacing: .1em;
  @include pc {
    font-size: 16px;
  }
  a {
    color: $e-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__body {
    @include pc {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }
  &__inner {
    @include pc {
    }
  }
  .e-fnav {
    padding: 60px 0 0;
    @include pc {
      padding-top: 90px;
    }
    &:not(:last-child) {
      @include pc {
        margin-right: 155px;
        max-width: 200px;
      }
    }
    &:last-child {
      @include pc {
        margin-left: auto;
      }
    }
    &__title {
      position: relative;
      margin-bottom: 2em;
      padding-left: 36px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 26px;
        height: 1px;
        background-color: $e-tertiary;
      }
    }
    &__list {
    }
    &__item {
      margin-top: 1.25em;
      &.-indent {
        padding-left: 1em;
      }
      span {
        padding-right: 1em;
        font-size: 10px;
        @include font-noto_sans_regular;
        white-space: nowrap;
        @include pc {
          font-size: 12px;
        }
      }
      a[target="_blank"] {
        &::after {
          content: '';
          display: inline-block;
          margin-left: .5em;
          width: 16px;
          height: 12px;
          background: url("/img/common/ico_blank_01.svg") no-repeat left top / contain;
        }
      }
    }
    &__info {
      margin-top: 30px;
      border: 1px solid $e-tertiary;
      padding: 15px 20px;
      color: $e-white;
      line-height: (42 / 24);
    }
  }
  &__foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 20px;
    @include pc {
      padding: 70px 0 40px;
    }
  }
  &__copy {
    @include font-Montserrat_regular;
    font-size: 9px;
    @include pc {
      font-size: 12px;
    }
  }
  &-sns {
    display: flex;
    justify-content: flex-end;
    @include pc {
    }
    &__item {
      @include pc {
      }
      & + & {
        margin-left: 17px;
      }
    }
    a {
      @include ov;
      img {
        width: auto;
        height: 16px;
      }
    }
  }
}
