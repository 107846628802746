@charset "utf-8";

//color
$e-base: #191919;
$e-white: #fff;
$e-primary: #e1dcd6;
$e-secondary: #a6a0a0;
$e-tertiary: #989693;
$e-dark: #2f2c29;
$e-gold: #b8b3ab;
$e-border: #e2dfdd;
$e-red: #990000ff;

//font
@mixin font-FilsonPro_Regular {
  font-family: filson-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin font-FilsonPro_Bold {
  font-family: filson-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// px（1240px）をvwに
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@function pxToVw($pxValue) {
  @return ((strip-unit($pxValue) / 1440) * 100) + vw;
}
