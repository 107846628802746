@charset "utf-8";

.e-shopLower {
  background-color: $e-secondary;
  color: $e-white;
  @include pc {
  }
  .e-title__01 {
    color: $e-white;
    span::before {
      background-color: $e-white;
    }
  }
  .e-inner + .e-inner {
    margin-top: 30px;
    @include pc {
      margin-top: 20px;
    }
  }
  &-news {
    &__list {
    }
    &__item {
      & + & {
        margin-top: 1em;
        border-top: 1px solid $e-gold;
        padding-top: 1em;
      }
      a[target="_blank"] {
        &::after {
          content: '';
          display: inline-block;
          margin-left: .5em;
          width: 16px;
          height: 12px;
          background: url("/img/common/ico_blank_01.svg") no-repeat left top / contain;
        }
      }
    }
  }
  &-map {
    &__map {
      position: relative;
      padding: calc(355 / 630 * 100%) 0 0;
      width: 100%;
      height: 0;
      @include pc {
        padding-top: calc(450 / 1240 * 100%);
      }
      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-info {
    &__table {
      display: block;
      margin-top: 40px;
      border-bottom: 1px solid $e-border;
      font-size: 13px;
      @include pc {
        margin-top: 60px;
        display: table;
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
      }
      tr {
        display: block;
        border-top: 1px solid $e-border;
        padding: 1em 0;
        @include pc {
          display: table-row;
          border-top: none;
          padding: 0;
        }
      }
      th,
      td {
        display: block;
        text-align: left;
        line-height: 2;
        @include pc {
          display: table-cell;
          border-top: 1px solid $e-border;
          padding: 1em;
        }
      }
      th {
        @include font-noto_sans_bold;
        font-size: 14px;
        color: #8b7e69;
        @include pc {
          width: 230px;
          background-color: #f8f8f7;
        }
      }
      td {
        flex: 1;
        @include pc {
        }
      }
      p {
        font: inherit;
        @include pc {
        }
      }
      a[target="_blank"] {
        &::after {
          content: '';
          display: inline-block;
          margin-left: .5em;
          width: 16px;
          height: 12px;
          background: url("/img/common/ico_blank_01.svg") no-repeat left top / contain;
        }
      }
      .note {
        margin-top: 1em;
        padding-left: 1em;
        text-indent: -1em;
        font-size: 12px;
        &::before {
          content: '※';
        }
        & + .note {
          margin-top: 0;
        }
      }
    }
    &__list {
      & + * {
        margin-top: 1em;
      }
    }
    &__item {
      padding-left: 1em;
      text-indent: -1em;
      &::before {
        content: '・';
      }
    }
    &__itemNote {
      padding-left: 1em;
      text-indent: -1em;
      &::before {
        content: '※';
      }
    }
  }
  .e-image {
    position: relative;
    margin: 60px 0 -200px;
    padding: 0 calc(60 / 750 * 100%);
    @include pc {
      display: flex;
      margin: 200px pxToVw(100px) -500px 0;
      padding: 0;
      width: calc(100% - #{pxToVw(100px)});
    }
    img {
      display: block;
      @include pc {
        width: 50%;
      }
    }
  }
  &__image01 {
  }
  &__image02 {
  }
}
