@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

input[type="text"],
input[type="submit"],
input[type="email"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  display: block;
}

fieldset {
  margin: 0;
  border: none;
}

legend {
  display: none;
}
