@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB{};
//@include onlyPC{};

/* */

html {
  font-size: 16px;
  @include font-noto_sans_regular;
  overflow: visible;
  @include sp {
    font-size: 62.5%;
  }
  @include pc {
    scroll-padding-top: 5.75rem;
  }
  @include wide {
    font-size: 16px;
  }
}

body {
  margin: 0;
  line-height: 1.6;
  color: #333;
  letter-spacing: 0.06em;
  word-break: break-word;
}

html > body {
  @include pc {
    min-width: 980px;
  }
}

a {
  color: #333;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

a[href^="tel:"] {
  @include tb {
    pointer-events: none;
    text-decoration: none;
  }
}

img {
  vertical-align: top;
  border: none;
  border-style: none;
  max-width: 100%;
}

ol,
ul,
li {
  list-style: none;
}

section {
  :not([class="p-top"]) > section {
    margin-bottom: 60px;
    @include pc {
      margin-bottom: 90px;
    }
  }
}

h1 {
  margin-top: 40px;
  margin-bottom: 26px;
  @include pc {
    margin-top: 125px;
    margin-bottom: 40px;
  }
}

h2 {
  margin-bottom: 30px;
}

p {
  font-size: 13px;
  @include pc {
    font-size: 14px;
  }
}

p + p {
  margin-top: 30px;
  @include pc {
    margin-top: 40px;
  }
}

.fade_bg {
  &__up,
  &__left,
  &__right {
    opacity: 0;
    transition: ease 1s;
    transition-duration: 1s;
    &._scroll__anime {
      opacity: 1;
    }
  }
  &__up {
    transform: translateY(50px);
    &._scroll__anime {
      transform: translateY(0);
    }
  }
  &__left {
    transform: translateX(-50px);
    &._scroll__anime {
      transform: translateX(0);
    }
  }
  &__right {
    transform: translateX(50px);
    &._scroll__anime {
      transform: translateX(0);
    }
  }
}
