@charset "utf-8";

.e-service {
  @include pc {
    padding-top: 320px;
  }
  &-01 {
    padding-bottom: 50px;
    @include pc {
      padding-bottom: 100px;
    }
    .e-title__01 {
      @include pc {
        margin-left: 60px;
      }
    }
    .e-image {
      margin: 30px 0 0 30px;
      @include pc {
        margin: 0;
      }
      .e-inner {
        position: relative;
      }
      img {
        @include pc {
          width: pxToVw(580px);
          height: pxToVw(580px);
        }
        @include wide {
          width: 580px;
          height: 580px;
        }
        &[src$="service_pic_01_pc.jpg"] {
          position: absolute;
          left: pxToVw(818px);
          top: pxToVw(-300px);
          width: pxToVw(622px);
          height: pxToVw(622px);
          @include wide {
            left: calc(718px + #{pxToVw(100px)});
            top: -300px;
            width: 622px;
            height: 622px;
          }
        }
      }
    }
    &__content {
      margin-top: pxToVw(-150px);
      margin-left: pxToVw(718px);
      @include wide {
        margin-top: -150px;
        margin-left: 718px;
      }
    }
  }
  &-02 {
    .e-inner__white {
      & + .e-inner__white {
        margin-top: 20px;
      }
    }
    &__row {
      border-top: 1px solid #edecef;
      @include pc {
        display: flex;
        justify-content: space-between;
        border-top: none;
      }
    }
    &__column {
      border-bottom: 1px solid #edecef;
      padding-top: 1em;
      padding-bottom: 1em;
      @include pc {
        border-bottom: none;
        padding: 0;
        width: 50%;
      }
      &:nth-child(2) {
        @include pc {
          border-left: 1px solid #edecef;
          padding-left: 60px;
        }
      }
    }
    p {
      font-size: 14px;
      @include pc {
        font-size: 16px;
      }
    }
    .e-button__black {
      margin-top: 20px;
      @include pc {
        margin-top: 40px;
      }
    }
  }
  &-03 {
    margin-top: 20px;
    &__list {
      border-bottom: 1px solid $e-border;
    }
    &__item {
      border-top: 1px solid $e-border;
      padding: 1.5em 0;
      .e-accordion {
        cursor: pointer;
      }
      .accordionBody {
        &.-hide {
          display: none;
        }
      }
    }
    &__head {
      @include ov;
      h5 {
      }
      p {
        font-size: 14px;
        @include pc {
          font-size: 16px;
        }
      }
    }
    &__title {
      position: relative;
      padding-right: 2.5em;
      @include font-noto_sans_bold;
      font-size: 14px;
      @include pc {
        font-size: 16px;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 9px;
        top: calc(50% - 3px);
        width: 12px;
        height: 6px;
        background: url("/img/common/ico_arrow_b_01.svg") no-repeat left top / contain;
        @include pc {
          right: 18px;
          top: calc(50% - 4.5px);
          width: 14px;
          height: 9px;
        }
        .-open & {
          transform: rotate(180deg);
        }
      }
    }
    &__content {
      display: none;
      padding: .5em 0;
      @include pc {
        padding: .5em calc(1.75em + 30px) .5em 1.75em;
      }
      .-open & {
        display: block;
      }
    }
    .e-button__black {
      margin-top: 20px;
      @include pc {
        margin-top: 40px;
      }
    }
  }
  &__list {
    counter-reset: item;
    margin-top: 15px;
    @include pc {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
    }
  }
  &__item {
    border: 1px solid $e-border;
    padding: 20px 10px;
    @include pc {
      padding: 30px 20px;
      width: calc(100% / 3 - 30px * 2 / 3);
    }
    & + & {
      margin-top: 15px;
      @include pc {
        margin-top: 0;
      }
    }
    &:not(:nth-child(3n+1)) {
      @include pc {
        margin-left: 30px;
      }
    }
    &:nth-child(n+4) {
      @include pc {
        margin-top: 30px;
      }
    }
  }
  &__head {
    position: relative;
    margin-bottom: 1em;
    @include font-noto_sans_bold;
    font-size: 13px;
    text-align: center;
    @include pc {
      font-size: 18px;
    }
    &::before {
      counter-increment: item;
      content: counter(item)'.';
      position: absolute;
      left: 0;
      @include font-Montserratfont-Montserrat;
      color: #bad4d5;
    }
    &.-gold::before {
      color: #d0bb9c;
    }
    h6 {
      font: inherit;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px;
    width: 80px;
    height: 80px;
    text-align: center;
    @include pc {
      margin-bottom: 40px;
      width: 100px;
      height: 100px;
    }
  }
  &__body {
    p {
      font-size: 12px;
      text-align: center;
      @include pc {
        font-size: 14px;
      }
      &.-small {
        @include pc {
          font-size: 13px;
        }
      }
      strong {
        @include font-noto_sans_bold;
      }
      small {
        font-size: 10px;
        @include pc {
          font-size: 12px;
        }
      }
      & + p {
        margin-top: 1em;
      }
    }
  }
  &__tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1.5em;
    font-size: 10px;
    @include pc {
    }
    span {
      display: inline-block;
      margin: 0 4px;
      padding: 0 1em;
      border: 1px solid #bad4d5;
      border-radius: 1em;
      min-width: 6em;
      text-align: center;
      &.-gold {
        border-color: #d0bb9c;
      }
    }
  }
}
