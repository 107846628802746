@charset "utf-8";

.e-faq {
  &__list {
  }
  &__item {
    border-top: 1px solid $e-border;
    padding: 1em 0;
    .e-accordion {
      cursor: pointer;
    }
    .accordionBody {
      &.-hide {
        display: none;
      }
    }
  }
  &__title {
    display: flex;
    position: relative;
    padding-right: 2.5em;
    font-size: 14px;
    @include ov;
    @include pc {
      font-size: 16px;
    }
    span {
      flex: 1;
    }
    &::before {
      content: 'Q.';
      margin-right: .5em;
      width: 1.5em;
      @include font-Montserratfont-Montserrat;
      color: #8b7e69;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 9px;
      top: calc(50% - 3px);
      width: 12px;
      height: 6px;
      background: url("/img/common/ico_arrow_b_01.svg") no-repeat left top / contain;
      @include pc {
        right: 18px;
        top: calc(50% - 4.5px);
        width: 14px;
        height: 9px;
      }
      .-open & {
        transform: rotate(180deg);
      }
    }
  }
  &__content {
    display: none;
    padding: .5em 0;
    @include pc {
      padding: .5em calc(1.75em + 30px) .5em 1.75em;
    }
    .-open & {
      display: block;
    }
  }
}
