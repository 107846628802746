@charset "utf-8";

.modaal {
  @at-root {
    .modaal-inner-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 20px;
      @include pc {
        padding: 0;
      }
    }
    .modaal-container {
      max-width: 1240px;
    }
    .modaal-content-container {
      padding: 0;
    }
    .modaal-wrapper {
      transition: all .3s;
      .modaal-close {
        background: #191919 url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzYuOCAzNi44IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0zNi44IDIuOC0yLjktMi44LTE1LjUgMTUuNi0xNS42LTE1LjYtMi44IDIuOCAxNS42IDE1LjYtMTUuNiAxNS41IDIuOCAyLjkgMTUuNi0xNS42IDE1LjUgMTUuNiAyLjktMi45LTE1LjYtMTUuNXoiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==') no-repeat center center / 17px auto;
        border-radius: 0;
        height: 50px;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
        @include pc {
          height: 70px;
          width: 70px;
        }
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}

.e-modal {
  @at-root {
    & {
      display: none;
    }
    .e-modal__inner {
      overflow-y: auto;
    }
    .e-modal-head {
      padding: 50px 20px 20px;
      @include pc {
        padding: 60px 80px 40px;
      }
      .title {
        font-size: 20px;
        text-align: center;
        line-height: 1.4;
        @include font-noto_sans_light;
        @include pc {
          font-size: 32px;
        }
      }
      .lead {
        font-size: 13px;
        margin-top: 1em;
        line-height: 1.8;
        @include font-noto_sans;
        @include tb{
          text-align: center;
        }
        @include pc {
          font-size: 16px;
        }
      }
      .text {
        font-size: 13px;
        margin-top: 1em;
        line-height: 1.8;
        @include font-noto_sans_light;
        @include pc {
          font-size: 14px;
        }
      }
    } // .modal-head
    .e-modal-body {
      padding: 0 20px 20px;
      @include pc {
        padding: 0 80px 60px;
      }
      .card {
        &-list {
          &.-type01 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin-right: -10px;
              margin-left: -10px;
            }
            @include pc {
              display: flex;
              margin-right: -20px;
              margin-left: -20px;
            }
            @media screen and (min-width: $point-pc) and (max-width: 1259px) {
              justify-content: center;
              flex-wrap: wrap;
            }
            @media screen and (min-width: 1260px) {
              justify-content: space-between;
              flex-direction: row;
            }
            .card-item {
              padding: 20px 0;
              @include onlyTB {
                padding: 20px;
                width: calc(100% / 2);
              }
              @include pc {
                padding: 0 20px 70px;
                position: relative;
              }
              @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                width: calc(100% / 3);
              }
              @media screen and (min-width: 1260px){
                width: calc(100% / 5);
              }
              &:not(:last-child) {
                border-bottom: 1px dotted#c7c7c7;
                @include tb {
                  border-bottom: none;
                  border-right: 1px dotted#c7c7c7;
                }
                @include onlyTB {
                  margin-bottom: 30px;
                }
              } // &:not(:last-child)
              &:nth-child(2n) {
                @include onlyTB {
                  border-right: none;
                }
              }
              &:nth-child(3) {
                @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                  border-right: none;
                }
              }
              &:nth-child(-n+3) {
                @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                  margin-bottom: 40px;
                }
              }
              .card-head {
                @include pc {
                  margin-bottom: 10px;
                }
                .title {
                  text-align: center;
                  line-height: 1.1;
                  .large {
                    font-size: 16px;
                    @include font-Montserrat_medium;
                    @include pc {
                      font-size: 18px;
                    }
                  }
                  .small {
                    font-size: 12px;
                    @include font-noto_sans_light;
                    @include pc {
                      font-size: 14px;
                    }
                  }
                }
              } // .card-head
              .card-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 10px;
                @include pc {
                  flex-direction: column;
                  padding-top: 0;
                }
                .left {
                  width: calc(50% - 10px);
                  @include pc {
                    width: 100%;
                  }
                  @include IE {
                    min-height: 0%;
                  }
                  .image {
                    max-width: 170px;
                    margin-left: auto;
                    margin-right: 0;
                    @include tb {
                      margin-right: auto;
                    }
                  }
                } // .left
                .right {
                  width: calc(50% - 10px);
                  @include pc {
                    width: 100%;
                  }
                  .caption {
                    font-size: 13px;
                    line-height: 1.8;
                    @include font-noto_sans_light;
                    @include pc {
                      text-align: center;
                      padding-top: 15px;
                    }
                  } // .caption
                  .lebel {
                    &-list {
                      font-size: 13px;
                      @include font-noto_sans_light;
                      @include pc {
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }
                    &-item {
                      .star {
                        color: #bfbfbf;
                        .red {
                          color: $c-primary;
                        }
                      }
                    } // &-item
                  } // .lebel
                } // .right
              } // .body
            } // .card-item
          } // &.-type01
          &.-type02 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
              margin-right: -20px;
              margin-left: -20px;
            }
            .card-item {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              padding: 20px 0;
              @include tb {
                width: calc(100% / 2);
                padding: 0 20px;
              }
              @include onlyTB {
                margin-bottom: 30px;
              }
              @include pc {
                width: calc(100% / 4);
                align-items: stretch;
                position: relative;
              }
              &:not(:last-child) {
                border-bottom: 1px dotted#c7c7c7;
                @include tb {
                  border-bottom: none;
                  border-right: 1px dotted#c7c7c7;
                }
              }
              &:nth-child(2n) {
                @include onlyTB {
                  border-right: none;
                }
              }
              .card-right {
                width: calc(50% - 10px);
                max-width: 170px;
                margin-left: 10px;
                margin-right: auto;
                @include tb {
                  max-width: none;
                  margin: auto;
                }
                @include pc {
                  margin-top: 0;
                  width: 100%;
                }
                .title {
                  @include pc {
                    text-align: center;
                    white-space: nowrap;
                  }
                  .large {
                    font-size: 16px;
                  }
                  .small {
                    font-size: 13px;
                  }
                }
                .caption {
                  font-size: 13px;
                  line-height: 1.8;
                  @include font-noto_sans_light;
                  @include pc {
                    padding-top: 190px; // 画像の高さ＋余白
                  }
                } // .caption
              } // .card-right
              .card-left {
                width: calc(50% - 10px);
                @include pc {
                  width: 100%;
                  position: absolute;
                  top: 60px;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
                .image {
                  max-width: 170px;
                  margin-left: auto;
                  margin-right: 10px;
                  @include tb {
                    margin-right: auto;
                  }
                }
              } // .card-left
            } // .card-item
          } // &.-type02
          &.-type03 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
            }
            @include pc {
              justify-content: flex-start;
            }
            .card-item {
              padding: 20px 0;
              &:not(:last-child) {
                @include sp {
                  border-bottom: 1px dotted#c7c7c7;
                }
              }
              @include onlyTB {
                &:not(:nth-child(2n + 1)) {
                  margin-left: 20px;
                }
              }
              @include tb {
                border: 1px solid #c7c7c7;
                padding: 20px;
                margin-bottom: 20px;
                width: calc(100% / 2 - 10px);
              }
              @include pc {
                width: calc(100% / 3 - 20px);
                &:not(:nth-child(3n + 1)) {
                  margin-left: 30px;
                }
              }
              .title {
                color: $c-primary;
                font-size: 16px;
                text-align: center;
              }
              .image {
                margin-top: 13px;
                img {
                  width: 100%;
                }
              }
              .lead {
                font-size: 13px;
                margin-top: 1em;
              }
              .caption {
                font-size: 13px;
                margin-top: 1em;
                @include font-noto_sans_light;
              }
              .price {
                background: #eef3f6;
                border-radius: 20px;
                font-size: 13px;
                margin-top: 1em;
                text-align: center;
                padding: .5em 0;
                @include font-noto_sans_light;
              }
            } // .card-item
          } // &.-type03
        } // &-list
      } // .card
    } // .modal-body
    .e-modal__close {
      display: block;
      font-size:  14px;
      margin: 20px auto 0;
      text-decoration: underline;
      text-align: center;
      @include pc {
        font-size: 20px;
      }
    }
  }
}
