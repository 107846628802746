@charset "utf-8";

.e-brand {
  background-color: $e-white;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 40px;
    @include pc {
      margin-bottom: 70px;
    }
    &.-line {
      margin-bottom: 30px;
      border-bottom: 1px solid $e-border;
      padding-bottom: 35px;
      @include pc {
        margin-bottom: 60px;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    width: calc(100% / 3);
    @include pc {
      padding: 0 20px;
      width: calc(100% / 5);
    }
    &:nth-child(n+4) {
      margin-top: 30px;
      @include pc {
        margin-top: 0;
      }
    }
    &:nth-child(n+6) {
      @include pc {
        margin-top: 60px;
      }
    }
    img {
      @include pc {
        width: auto;
        height: auto;
        max-height: 100px;
      }
    }
  }
}
