@charset "utf-8";

.e-news {
  .e-inner {
    @include pc {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  &__list {
    border-bottom: 1px solid $e-gold;
    font-size: 14px;
    @include pc {
      max-width: 930px;
      width: pxToVw(930px);
      font-size: 14px;
    }
  }
  &__item {
    border-top: 1px solid $e-gold;
    padding: 1.5em 0;
    font: inherit;
    @include pc {
      display: flex;
    }
  }
  &__date {
    display: block;
    margin-bottom: .25em;
    @include font-FilsonPro_Regular;
    @include pc {
      margin: 0;
      width: 8em;
      font-size: 16px;
    }
  }
  &__text {
    line-height: (30 / 14);
    @include pc {
      flex: 1;
    }
  }
}
