@charset "utf-8";

.e-brandLower {
  padding-top: 300px;
  @include pc {
    padding-top: 500px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .e-content {
    @include pc {
      width: calc(#{pxToVw(465px)} + #{pxToVw(100px)});
    }
    .e-inner {
      @include pc {
        padding-right: 0;
      }
    }
  }
  .e-image {
    margin: 30px 0 0 30px;
    @include pc {
      margin: 0;
      width: pxToVw(722px);
    }
    img {
      width: 100%;
    }

  }
  > .e-inner__white {
    overflow: hidden;
    margin-top: 75px;
    @include pc {
      margin-top: 170px;
      margin-left: pxToVw(100px);
      padding-bottom: 40px;
    }
  }
  &-list {
    width: 100%;
    @include pc {
    }
    &__item {
      line-height: 2;
      @include pc {
      }
    }
    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      height: 140px;
      text-align: center;
      @include pc {
        height: 140px;
      }
      img {
        max-width: 80%;
      }
    }
    &__title {
      @include font-noto_sans_bold;
      font-size: 14px;
      color: #8b7e69;
      @include pc {
      }
    }
    &__brand {
      margin-bottom: .5em;
      @include font-noto_sans_medium;
      font-size: 12px;
      @include pc {
        font-size: 16px;
      }
    }
    &__body {
      @include pc {
      }
      .e-title__05 {
        margin-bottom: 1em;
      }
    }
    &__image {
      margin-top: 15px;
      @include pc {
        margin-top: 30px;
      }
    }
  }
  .swiper {
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
      margin: 40px 0 10px;
      font-size: 0;
      color: $e-white;
      transform: none;
      @include pc {
        margin-bottom: 0;
      }
      &::after {
        order: 2;
        content: '';
        display: block;
        margin: 0 10px;
        width: 20px;
        height: 1px;
        background-color: #201f1f;
        @include pc {
          width: 28px;
        }
      }
      &-next,
      &-prev {
        position: static;
        margin: 0;
        width: 9px;
        height: 14px;
        background: url("/img/common/ico_arrow_r_02.svg") no-repeat left top / contain;
        &::after {
          display: none;
        }
      }
      &-next {
        order: 3;
      }
      &-prev {
        order: 1;
        transform: rotate(180deg);
      }
    }
  }
}
