@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700");

@font-face {
  font-family: "Noto Sans Japanese";
  font-style: normal;
  src: url("/font/NotoSansCJKjp-DemiLight.woff2") format("woff2"), url("/font/NotoSansCJKjp-DemiLight.woff") format("woff"), url("/font/NotoSansCJKjp-DemiLight.ttf") format("truetype"), url("/font/NotoSansCJKjp-DemiLight.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Noto Sans Japanese Regular";
  font-style: normal;
  src: url("/font/NotoSansCJKjp-Regular.woff2") format("woff2"), url("/font/NotoSansCJKjp-Regular.woff") format("woff"), url("/font/NotoSansCJKjp-Regular.ttf") format("truetype"), url("/font/NotoSansCJKjp-Regular.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Noto Sans Japanese Medium";
  font-style: normal;
  src: url("/font/NotoSansCJKjp-Medium.woff2") format("woff2"), url("/font/NotoSansCJKjp-Medium.woff") format("woff"), url("/font/NotoSansCJKjp-Medium.ttf") format("truetype"), url("/font/NotoSansCJKjp-Medium.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Noto Sans Japanese Bold";
  font-style: normal;
  src: url("/font/NotoSansCJKjp-Bold.woff2") format("woff2"), url("/font/NotoSansCJKjp-Bold.woff") format("woff"), url("/font/NotoSansCJKjp-Bold.ttf") format("truetype"), url("/font/NotoSansCJKjp-Bold.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Noto Sans Japanese Black";
  font-style: normal;
  src: url("/font/NotoSansCJKjp-Black.woff2") format("woff2"), url("/font/NotoSansCJKjp-Black.woff") format("woff"), url("/font/NotoSansCJKjp-Black.ttf") format("truetype"), url("/font/NotoSansCJKjp-Black.eot") format("embedded-opentype");
}

//@include font-noto_sans_light;
@mixin font-noto_sans_light {
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 200;
}

//@include font-noto_sans;
@mixin font-noto_sans {
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 300;
}

//@include font-noto_sans_regular;
@mixin font-noto_sans_regular {
  font-family: "Noto Sans Japanese Regular", sans-serif;
  font-weight: 400;
}

//@include font-noto_sans_medium;
@mixin font-noto_sans_medium {
  font-family: "Noto Sans Japanese Medium", sans-serif;
  font-weight: 500;
}

//@include font-noto_sans_bold;
@mixin font-noto_sans_bold {
  font-family: "Noto Sans Japanese Bold", sans-serif;
  font-weight: 700;
}

//@include font-noto_sans_black;
@mixin font-noto_sans_black {
  font-family: "Noto Sans Japanese Black", sans-serif;
  font-weight: 900;
}

//@include font-Montserrat_extralight;
@mixin font-Montserrat_extralight {
  font-family: "Montserrat", "Noto Sans Japanese", sans-serif;
  font-weight: 200;
}

//@include font-Montserrat;
@mixin font-Montserrat {
  font-family: "Montserrat", "Noto Sans Japanese", sans-serif;
  font-weight: 300;
}

//@include font-Montserrat_regular;
@mixin font-Montserrat_regular {
  font-family: "Montserrat", "Noto Sans Japanese Regular", sans-serif;
  font-weight: 400;
}

//@include font-Montserrat_medium;
@mixin font-Montserrat_medium {
  font-family: "Montserrat", "Noto Sans Japanese Regular", sans-serif;
  font-weight: 500;
}

//@include font-Montserrat_semiBold;
@mixin font-Montserrat_semiBold {
  font-family: "Montserrat", "Noto Sans Japanese Regular", sans-serif;
  font-weight: 600;
}

//@include font-Montserrat_bold;
@mixin font-Montserratfont-Montserrat {
  font-family: "Montserrat", "Noto Sans Japanese Regular", sans-serif;
  font-weight: 700;
}

//@include fz();
@mixin fz($size) {
  font-size: $size + px;
  @include sp320 {
    font-size: $size + px;
    font-size: ($size / 10 * 0.85) + rem;
  }
}

//@include vw();
@mixin vw($size) {
  font-size: ($size / 1440 * 100) + vw;
  @include wide {
    font-size: $size + px;
  }
}
