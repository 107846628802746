@charset "utf-8";

$point-sp320: 320px;
$point-sp: 767px;
$point-tb: 768px;
$point-pc: 980px;
$point-wide: 1440px;

//color
$c-primary: #e60012;
$c-text: #000;
$c-base: #f0f5f6;
$c-base2: #f9fbfb;
$c-border1: #bad3d6;

//@include sp320{}
@mixin sp320 {
  @media screen and (max-width: $point-sp320) {
    @content;
  }
}

//@include sp{}
@mixin sp {
  @media screen and (max-width: $point-sp) {
    @content;
  }
}

//@include tb{}
@mixin tb {
  @media screen and (min-width: $point-tb) {
    @content;
  }
}

//@include pc{}
@mixin pc {
  @media screen and (min-width: $point-pc) {
    @content;
  }
}

//@include wide{}
@mixin wide {
  @media screen and (min-width: $point-wide) {
    @content;
  }
}

//@include pcIE{}
@mixin pcIE {
  @media screen and (min-width: $point-pc) and (-ms-high-contrast: none) {
    @content;
  }
}

//@include IE{}
@mixin IE {
  @media screen and (-ms-high-contrast: none) {
    @content;
  }
}

//@include onlyTB{}
@mixin onlyTB {
  @media (min-width: $point-tb) and (max-width: 979px) {
    @content;
  }
}

//@include onlyPC{}
@mixin onlyPC {
  @media (min-width: $point-pc) and (max-width: $point-wide) {
    @content;
  }
}

//@include linkColor($color);
@mixin linkColor($color) {
  color: $color;
  &:hover,
  &:active,
  &:focus {
    color: lighten($color, 20%);
  }
}

//@include clearfix;
@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

// @include ov;
@mixin ov {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
    img {
      background-color: rgba(#fff, 0.01);
    }
  }
}

//@include lhCrop(line-height/fontsize);

//.hoge{
//  @include lhCrop(80/40);
//  font-size: 40px;
//  line-height: (80/40);
//}

@mixin lhCrop($line-height) {
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
    content: "";
  }
}

// @include dotLineBlack;
@mixin dotLineBlack {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    content: "";
    background-image: linear-gradient(to right, #000, #000 1px, transparent 1px, transparent 2.5px);
    background-repeat: repeat-x;
    background-size: 2.5px 1px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: url("/img/common/line_dot_black.svg");
      background-size: auto 2px;
    }
    @include IE{
      background-size: 5px 2px;
    }
  }
}

// @include dotLineGray;
@mixin dotLineGray {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    content: "";
    background-image: linear-gradient(to right, #b7bdbf, #b7bdbf 1px, transparent 1px, transparent 2.5px);
    background-repeat: repeat-x;
    background-size: 2.5px 1px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: url("/img/common/line_dot_gray.svg");
      background-size: auto 2px;
    }
    @include IE{
      background-size: 5px 2px;
    }
  }
}

// @include dotLine($line-color);
@mixin dotLine($line-color) {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    content: "";
    background-image: linear-gradient(to right, $line-color, $line-color 1px, transparent 1px, transparent 2.5px);
    background-repeat: repeat-x;
    background-size: 2.5px 1px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: url("/img/common/line_dot_black.svg");
      background-size: auto 2px;
    }
    @include IE{
      background-size: 5px 2px;
    }
  }
}

// @include dotLineL($line-color);
@mixin dotLineL($line-color) {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1.5px;
    content: "";
    background-image: linear-gradient(to right, $line-color, $line-color 1.5px, transparent 1.5px, transparent 5px);
    background-repeat: repeat-x;
    background-size: 5px 1.5px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: linear-gradient(to right, $line-color, $line-color 2px, transparent 2px, transparent 5px);
      background-size: 5px 2px;
    }
  }
}

// @include dotLineAfter($line-color2);
@mixin dotLineAfter($line-color2) {
  position: relative;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    content: "";
    background-image: linear-gradient(to right, $line-color2, $line-color2 1px, transparent 1px, transparent 2.5px);
    background-repeat: repeat-x;
    background-size: 2.5px 1px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: linear-gradient(to right, $line-color2, $line-color2 2px, transparent 2px, transparent 5px);
      background-size: 5px 2px;
    }
  }
}

// @include dotLineLAfter($line-color3);
@mixin dotLineLAfter($line-color3) {
  position: relative;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1.5px;
    content: "";
    background-image: linear-gradient(to right, $line-color3, $line-color3 1.5px, transparent 1.5px, transparent 5px);
    background-repeat: repeat-x;
    background-size: 5px 1.5px;
    @media screen and (min-width: 980px) {
      height: 2px;
      background-image: linear-gradient(to right, $line-color3, $line-color3 2px, transparent 2px, transparent 5px);
      background-size: 5px 2px;
    }
  }
}

// @include visually-hidden;
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}
