@charset "utf-8";

.e-philosophy {
  padding-top: 0;
  background-color: $e-secondary;
  color: $e-white;
  @include pc {
    padding-bottom: 0;
  }
  .e-title__01 {
    color: $e-white;
    span::before {
      background-color: $e-white;
    }
  }
  .e-inner__inner {
    max-width: 940px;
  }
  .e-image {
    margin: 0 30px 60px 0;
    @include pc {
      margin: 0 0 120px;
      width: pxToVw(1340px);
    }
    img {
      width: 100%;
    }
  }
  .e-inner__white {
    margin-top: 30px;
    @include pc {
      position: relative;
      margin-top: 0;
      bottom: -60px;
    }
  }
  .e-button__black {
    margin-top: 20px;
    @include pc {
      margin-top: 40px;
    }
  }
}
